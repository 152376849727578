export const InputSelecList = ({ id, name, label, onChange, value, placeholder, disabled, options }) => {
    return (
        <div>
            <label
                htmlFor={name}
                className="block text-md font-semibold text-gray-900 leading-6 ml-1"
            >
                {label}
            </label>
            <div className="mt-2 relative">
                <select
                    className="block w-full focus:bg-white rounded-md border-0 py-2 px-4 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 appearance-none pr-10" // Añadido pr-10 para padding derecho
                    id={id}
                    name={name}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                >
                    {/* Opción por defecto (placeholder) */}
                    <option value="" disabled>
                        {placeholder}
                    </option>
                    {/* Mapeo de las opciones */}
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                {/* Ícono de flecha personalizado */}
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                        className="h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};