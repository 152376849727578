import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../components/loading/Loading';
import { formatDateTime } from '../../common/formatDateText';
import { capitalizarPalabras } from '../../common/upperCaseWord';
import { Tooltip, Zoom } from '@mui/material';
import { useRef, useState } from 'react';
import { AddNoteUserModal } from './AddNoteUserModal';
import { CorfimationCloseNoteModal } from './ConfirmationCloseNoteModal';
import { getAllNotesByUserId, updateNoteStatus } from '../../redux/actions/noteUser';
import { toast } from 'react-hot-toast';

export const NotaUserCard = ({ userId }) => {
    const dispatch = useDispatch();
    const { notes, loading: loadingNotes } = useSelector((state) => state.noteUser);
    const [openAddNoteUserModal, setOpenAddNoteUserModal] = useState(false);
    const [loadingConfirmCloseNote, setLoadingConfirmCloseNote] = useState(false);
    const [openConfirmationCloseNoteModal, setOpenConfirmationCloseNoteModal] = useState(false);
    const [noteId, setNoteId] = useState();
    const cancelCreateCourseRef = useRef(null);

    const handleUpdateNoteStatus = () => {
        setLoadingConfirmCloseNote(true);
        dispatch(updateNoteStatus(noteId))
            .then((result) => {
                if (result.status === 200) {
                    toast.success(result.message);
                    dispatch(getAllNotesByUserId(userId));
                    setOpenConfirmationCloseNoteModal(false);
                    setLoadingConfirmCloseNote(false);
                }
            })
            .catch(() => {
                setLoadingConfirmCloseNote(false);
            })
    }

    const handleOpenConfirmationCloseNote = (noteId) => {
        setOpenConfirmationCloseNoteModal(true);
        setNoteId(noteId);
    }

    return (
        <div className="w-full mx-auto">
            <div className='w-full flex justify-end'>
                <Tooltip title="Agregar nota" TransitionComponent={Zoom} placement='top'>
                    <button
                        type='button'
                        onClick={() => setOpenAddNoteUserModal(true)}
                        className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
                    >Agregar una nota</button>
                </Tooltip>
            </div>
            {
                loadingNotes ? (
                    <Loading />
                ) : (
                    <div
                        className="w-full flex flex-col h-[500px] bg-indigo-900/5 rounded-md mt-4 overflow-auto p-4 gap-4"
                    >

                        {
                            notes.length > 0
                                ? notes.map(({ _id: id, createdBy, title, content, category, updatedAt, status }) => (
                                    <div key={id}>
                                        <div className='w-full flex flex-col bg-white border border-gray-50 p-4 rounded-md gap-4 shadow-md'>
                                            <div className='w-full'>
                                                <p className='text-[1.2rem] font-[600] break-words uppercase'>{title}</p>
                                                <p className='text-[.9rem] font-[600] capitalize text-indigo-600 break-words'>{`${createdBy?.firstName} ${createdBy?.lastName}`}</p>
                                                <p className='text-[.7rem] font-[400] capitalize text-gray-600 break-words'>{`Departamento ${category}`}</p>
                                            </div>
                                            <div className='w-full p-4 border border-gray-50 bg-indigo-50 rounded-md'>
                                                <p className='text-sm break-words'>{content}</p>
                                            </div>
                                            <div className='w-full flex justify-end'>
                                                <p className='text-[.8rem] text-gray-400'>{capitalizarPalabras(formatDateTime(updatedAt))}</p>
                                            </div>
                                            {status && (
                                                <div className='w-full flex justify-end'>
                                                    <button
                                                        type='button'
                                                        onClick={() => handleOpenConfirmationCloseNote(id)}
                                                        className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
                                                    >Cerrar Nota</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))
                                : (
                                    <p className='text-gray-400'>Este estudiante no tiene registro de notas.</p>
                                )
                        }
                    </div>
                )
            }
            <AddNoteUserModal
                open={openAddNoteUserModal}
                setOpen={setOpenAddNoteUserModal}
                userId={userId}
            />
            <CorfimationCloseNoteModal
                open={openConfirmationCloseNoteModal}
                setOpen={setOpenConfirmationCloseNoteModal}
                cancelButtonRef={cancelCreateCourseRef}
                confirmAction={handleUpdateNoteStatus}
                title={'Cerrar nota'}
                message={'Estas seguro de querer cerrar esta nota?'}
                labelButonConfirm={'Cerrar nota'}
                loading={loadingConfirmCloseNote}
            />
        </div>
    )
}
