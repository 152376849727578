import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@headlessui/react';
import { Modal } from '../../components/modal/Modal';
import { toast } from 'react-hot-toast';

import { InputText } from '../../components/inputs/InputText';
import { ButtonLoader } from '../../components/buttons/ButtonLoader';
import { InputTextArea } from '../../components/inputTextArea/InputTextArea';
import { createNoteUser, getAllNotesByUserId } from '../../redux/actions/noteUser';
import { InputSelecList } from '../../components/inputSelecList/InputSelecList';
import { categories } from '../../static/data';
import { InputSwitch } from '../../components/inputSwitch/InputSwitch';

export const AddNoteUserModal = ({ open, setOpen, userId }) => {
	const dispatch = useDispatch();
	const cancelButtonRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [activateNote, setActivateNote] = useState(false);
	const { user } = useSelector((state) => state.user);
	const { _id: createdId } = user;
	const [formData, setFormData] = useState({
		userId: userId ? userId : "",
		title: '',
		content: '',
		category: '',
		createdBy: createdId ? createdId : "",
		status: false,
	});

	useEffect(() => {
		setFormData({
			...formData,
			title: '',
			content: '',
			category: '',
			status: false,
		});
		setActivateNote(false);
		setLoading(false);
	}, [open]);


	const onChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	}

	const formValidations = () => {
		const { title, category, content } = formData;

		if (!title && !content) {
			toast.error('Ingresa un titulo y el contenido de la nota');
			setLoading(false);
			return false;
		}

		if (!title) {
			toast.error('Ingresa un titulo para su nota');
			setLoading(false);
			return false;
		}

		if (!category) {
			toast.error('Selecciona una categoria para la nota');
			setLoading(false);
			return false;
		}

		if (!content) {
			toast.error('Ingresa el contenido de la nota');
			setLoading(false);
			return false;
		}

		return true;
	}

	const handleAddUserNote = () => {
		setLoading(true);
		const formValidation = formValidations();
		if (formValidation) {
			const updatedState = {...formData, status: activateNote};
			dispatch(createNoteUser(updatedState))
				.then((result) => {
					if (result.status === 201) {
						toast.success(result.message);
						setOpen(false);
						setFormData({
							...formData,
							title: '',
							content: '',
							category: '',
							status: '',
						});
						dispatch(getAllNotesByUserId(userId));
					} else {
						toast.error(result.message);
					}
					setLoading(false);
				});
		}
	}

	return (
		<Modal open={open} setOpen={setOpen} cancelButtonRef={cancelButtonRef}>
			<div className="w-full px-4 py-4">
				<Dialog.Title as="h3" className="text-center text-lg font-semibold leading-6 text-gray-950">
					Crear una nueva nota
				</Dialog.Title>
				<div className="mt-6">
					<p className="text-sm text-center text-gray-600">
						Ingresa un titulo para tu nota y su contenido.
					</p>
				</div>
				<div className='flex items-start mt-2 min-h-[300px]'>
					<div className='w-full mx-auto grid grid-cols-1 gap-6 m-10'>
						<div>
							<InputText
								id={'title'}
								name={'title'}
								type={'title'}
								label={'Titulo de la nota'}
								onChange={(e) => onChange(e)}
								value={formData.title}
								placeholder={'Ingresa un titulo para tu nota'}
								disabled={false}
							/>
						</div>
						<div>
							<InputSelecList
								id="category"
								name="category"
								label="Categoria"
								onChange={(e) => onChange(e)}
								value={formData.category}
								placeholder="Categoria de la nota"
								options={categories}
							/>
						</div>
						<div className='w-full flex flex-col align-middle ring-1 ring-indigo-900/10 rounded-sm px-4 pt-2 pb-6 shadow-sm '>
							<InputSwitch
								enabled={activateNote}
								setEnabled={setActivateNote}
								label={activateNote ? "Mantener esta nota activa" : "Mantener esta nota activa"}
								disabled={false}
							/>
						</div>
						<div>
							<InputTextArea
								id={'content'}
								name={'content'}
								type={'content'}
								label={'Agregue el contenido de su nota'}
								onChange={(e) => onChange(e)}
								value={formData.content}
								placeholder={'Contenido de la nota...'}
							/>
						</div>
						<div className='mt-[20px]'>
							<button
								type='button'
								disabled={loading}
								className='disabled:cursor-not-allowed rounded-lg transition py-2.5 font-semibold text-md text-white text-center bg-indigo-600 w-full'
								onClick={handleAddUserNote}
							>
								{loading
									? <ButtonLoader />
									: 'Agregar nota'
								}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
